import App from '../components/App'
import Footer from '../components/Footer'
import Header from '../components/Header'
import HowPsychologistHelp from '../organisms/HowPsychologistHelp'
import InfoPanicAttack from '../organisms/InfoPanicAttack'
import PanicAttackHeader from '../organisms/PanicAttackHeader'
import React from 'react'
import SEO from '../atoms/SEO'
import SignsPanicAttack from '../organisms/SignsPanicAttack'
import WhatDoPanicAttack from '../organisms/WhatDoPanicAttack'
import WhatIsPanicAttack from '../organisms/WhatIsPanicAttack'
import WherePsychologistPanicAttack from '../organisms/WherePsychologistPanicAttack'
import WhyHelpPanicAttack from '../organisms/WhyHelpPanicAttack'
import styled from 'styled-components'
import { AnexityArticles } from '../organisms/AnexityArticles'
import { AnexityPsychologists } from '../organisms/AnexityPsychologists'
import { CommentsSection } from '../organisms/CommentsSection'
import { HowItSection } from '../organisms/HowItSection'
import { RightChoiseTariffSection } from '../organisms/RightChoiseTariffSection'
import { color } from '../styles/vars/colors'
import { graphql } from 'gatsby'
import { pick } from 'rambda'
import { size } from '../constants'

const Page = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 76px repeat(2, auto);
  min-height: calc(var(--vh, 1vh) * 100);
  background: #e6f0fd;

  @media (max-width: ${size.xs}) {
    grid-template-rows: 56px repeat(2, auto);
  }
`

const Main = styled.div`
  & ${RightChoiseTariffSection} {
    background: ${color.background};
    padding-top: 71px;
    padding-bottom: 115px;

    @media (max-width: ${size.lg}) {
      padding-top: 31px;
      padding-bottom: 119px;
    }

    @media (max-width: ${size.md}) {
      padding-top: 24px;
      padding-bottom: 69px;
    }

    @media (max-width: ${size.sm}) {
      padding-top: 26px;
      padding-bottom: 44px;
    }

    @media (max-width: ${size.xs}) {
      padding-top: 16px;
      padding-bottom: 32px;
    }
  }

  & ${HowItSection} {
    @media (max-width: ${size.sm}) {
      padding-top: 40px !important;
    }
  }

  & section {
    max-width: 100vw;
  }
`

const PanicAttack = ({ data }) => (
  <App>
    <SEO
      description="Онлайн-консультации психолога при панических атаках ✔Более 280 психологов ✔Онлайн сессия от 2000 ₽ ✔Анонимный прием ➨Быстрый подбор специалиста под вашу проблему"
      title="Психологическая помощь при панических атаках — онлайн консультация психолога | YouTalk"
    />
    <Page>
      <Header />
      <Main>
        <PanicAttackHeader />
        <WhatIsPanicAttack />
        <SignsPanicAttack />
        <InfoPanicAttack />
        <WhatDoPanicAttack />
        <HowPsychologistHelp />
        <WhyHelpPanicAttack />
        <WherePsychologistPanicAttack />
        <AnexityPsychologists
          data={pick(['psychologists', 'workAreas'], data)}
          title="Специалисты, которые могут помочь справиться с паническими атаками"
        />
        <HowItSection />
        <CommentsSection />
        <AnexityArticles data={data.articles} />
      </Main>
      <Footer />
    </Page>
  </App>
)

export default PanicAttack

export const query = graphql`
  query PanicAttack {
    articles: allMdx(
      sort: { order: DESC, fields: frontmatter___created_at }
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { tags: { elemMatch: { title: { eq: "тревога" } } } }
      }
    ) {
      totalCount
      nodes {
        frontmatter {
          announcement
          created_at(formatString: "DD.MM.YYYY")
          color
          title
          author
          tags {
            title
          }
          img {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        slug
        id
      }
    }
    workAreas: allWorkAreasData(
      filter: {
        tag: { in: ["Тревога, волнение", "Страхи, фобии", "Панические атаки"] }
      }
    ) {
      edges {
        node {
          id
        }
      }
    }
    psychologists: allPsychologistsData(
      filter: {
        work_areas: {
          elemMatch: {
            tag: {
              in: ["Тревога, волнение", "Страхи, фобии", "Панические атаки"]
            }
          }
        }
        is_active: { eq: true }
      }
    ) {
      edges {
        node {
          id
          _id
          is_active
          about
          approaches {
            _id
            id
            name
          }
          audio
          birthday_date
          educations {
            speciality
            title
            type
            yearEnd
            yearStart
          }
          english
          family
          surname
          features
          gender
          individual
          messages
          is_active
          name
          non_working_areas {
            _id
            id
            tag
          }
          photoUrl
          professions {
            profession_name
          }
          self_appointment
          sync_messages
          start_of_practice
          video
          work_areas {
            _id
            group {
              name
              type
            }
            id
            tag
          }
        }
      }
      totalCount
    }
  }
`
